import React from 'react';
import { isEmpty, get, reduce, isEqual, max, capitalize, groupBy, find } from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  formatTime,
  formatDate,
  formatTimeTo12H,
  differenceBetween,
} from '../utils/fudrLocalizationHelper';
import { getItem } from '../utils/storage';
import {
  restaurantOperatingMode,
  renderServiceType,
  buttonSize,
  paymentTypes,
  customErrorCodes,
  upsellSearchErrorCase,
  orderStatusConfig,
  refundTypesConfig,
  refundStatusConfig,
  transactionStatusConfig,
  transactionCategory,
  cashCardTransactionTypes,
  addressType,
  currencies,
  offerTypeCode,
  storageTypes,
  transactionSubStatusConfig,
} from '../constants/globals';
import { toTitleCase, renderCount, sortObjectByKeysUsingComparator } from '../utils/helpers';
import { constants, trueCallerConfig } from '../constants/configuration';
import resourcesConfig from '../resources';
import asyncRoute from '../helpers/hoc/asyncRoute';
import routes from '../routes';
// all selectors
import { createSelector } from 'reselect';

import vegetarianIcon from '../assets/images/icons/vegetarian.svg';
import nonVegetarianIcon from '../assets/images/icons/non-vegetarian.svg';
import eggetarianIcon from '../assets/images/icons/eggetarian.svg';
import defaultFoodIcon from '../assets/images/icons/default-food-type.svg';
import rechargeSuccessIcon from '../assets/images/icons/recharge-successful.svg';
import warningYellowIcon from '../assets/images/icons/warning-yellow.svg';
import warningBlackIcon from '../assets/images/icons/warning-black.svg';

import unplacedOrderImg from '../assets/images/unplaced-order.svg';
import placedOrderImg from '../assets/images/placed-order.svg';
import deliveredOrderImg from '../assets/images/delivered-order.svg';
import failedOrderImg from '../assets/images/failed-order.svg';
import rejectedOrderImg from '../assets/images/rejected-order.svg';
import readyOrderImg from '../assets/images/ready-order.svg';
import partiallyConfirmedOrderImg from '../assets/images/partiallyConfirmed-order.svg';
import outForDeliveryImg from '../assets/images/out-for-delivery.svg';
import mobileIcon from '../assets/images/icons/mobile.svg';
import appetizerLong from '../assets/images/icons/appetizer-long.svg';
import appetizerShort from '../assets/images/icons/appetizer-short.svg';
import starFillIcon from '../assets/images/icons/star_fill.svg';
import moment from 'moment';

const Button = asyncRoute(() => import('../components/MaterialElements/Button'));

const upsellConfig = constants.upsell;
const authenticationConfig = constants.authentication;

export const selectApp = (state) => state.app;

export const selectCart = (state) => selectApp(state).viewCart;

export const selectMenu = (state) => selectApp(state).menu;

export const selectRestaurant = (state) => selectApp(state).restaurant;

export const selectUpdatedScanCode = (state) => selectApp(state).updatedScanCode;

/**
 * Get value of an additional property of a restaurant
 *
 * @param {Object} restaurant Restaurant data
 * @param {String} propCode Additional property code
 * @returns {String} Additional property value
 */
export const getRestaurantAdditionalPropertyValue = (restaurant, propCode) => {
  const restaurantConfig = get(restaurant, 'restaurantConfig', {});
  const additionalProps = get(restaurantConfig, 'additionalProperties', []);
  const matchingProp = additionalProps.find((prop) => get(prop, 'code') === propCode) || {};
  return get(matchingProp, 'value', '');
};

export const selectMenuItemsMap = createSelector(selectMenu, (menu) => {
  if (isEmpty(menu)) {
    return [];
  } else {
    let menuItemsMap = {};
    for (const section of menu) {
      if (!isEmpty(section)) {
        const { categories, menuItems } = section;
        if (isEmpty(categories) && !isEmpty(menuItems)) {
          for (const menuItem of menuItems) {
            menuItemsMap[menuItem.id] = { ...menuItem };
          }
        } else {
          if (!isEmpty(categories)) {
            for (const category of categories) {
              const { menuItems } = category;
              if (!isEmpty(menuItems)) {
                for (const menuItem of menuItems) {
                  menuItemsMap[menuItem.id] = { ...menuItem };
                }
              }
            }
          }
        }
      }
    }
    return menuItemsMap;
  }
});

export const getTotalItemCount = (selectedItem) =>
  reduce(
    selectedItem,
    function (count, item) {
      return count + parseInt(get(item, 'quantity', 0));
    },
    0,
  );

export const renderFoodTypeIcon = (type) => {
  switch (type) {
    case 'VEGETARIAN':
      return <img src={vegetarianIcon} />;
    case 'NON_VEGETARIAN':
      return <img src={nonVegetarianIcon} />;
    case 'EGG':
      return <img src={eggetarianIcon} />;
    default:
      return <img src={defaultFoodIcon} />;
  }
};

// TODO: Check if this CTA getter is needed any longer
export const getOrderButtonCTA = ({ availablePaymentTypes, paymentType }) => {
  if (
    availablePaymentTypes.length === 1 &&
    isEqual(availablePaymentTypes[0], paymentTypes.PREPAYMENT)
  ) {
    return constants.payment.paymentCTA;
  } else if (isEmpty(availablePaymentTypes) && isEqual(paymentType, paymentTypes.PREPAYMENT)) {
    return constants.payment.paymentCTA;
  } else {
    return constants.payment.orderNow;
  }
};

export const renderOrderStatusImage = ({ orderStatus, cancelledItems, tableType }) => {
  let image = <></>;
  if (isEqual(orderStatus, 'CONFIRMED') && cancelledItems && cancelledItems.length > 0) {
    image = <img alt="draft" src={partiallyConfirmedOrderImg} />;
  } else {
    switch (orderStatus) {
      case 'DRAFT':
        image = <img alt="draft" src={unplacedOrderImg} />;
        break;
      case 'UNATTENDED':
        image = <img alt="draft" src={unplacedOrderImg} />;
        break;
      case 'CONFIRMED':
        image = <img alt="confimred" src={placedOrderImg} />;
        break;
      case 'SETTLED':
        image = <img alt="delivered" src={deliveredOrderImg} />;
        break;
      case 'FAILED':
        image = <img alt="failed" src={failedOrderImg} />;
        break;
      case 'CANCELLED':
        image = <img alt="rejected" src={rejectedOrderImg} />;
        break;
      case 'READY':
        switch (tableType) {
          case 'SP':
            image = <img alt="ready" src={readyOrderImg} />;
            break;
          case 'DL':
            image = <img alt="out-for-delivery" src={outForDeliveryImg} />;
            break;
          case 'DT':
            image = <img alt="out-for-delivery" src={readyOrderImg} />;
            break;
          default:
        }
        break;
      default:
    }
  }
  return image;
};

export const renderOrderStatus = ({ orderStatus, cancelledItems, tableType }) => {
  let status = '';
  if (isEqual(orderStatus, 'CONFIRMED') && cancelledItems && cancelledItems.length > 0) {
    status = 'Order Partially Confirmed';
  } else {
    switch (orderStatus) {
      case 'DRAFT':
        status = 'Order Placed';
        break;
      case 'UNATTENDED':
        status = 'Order Placed';
        break;
      case 'CONFIRMED':
        status = 'Order Confirmed';
        break;
      case 'SETTLED':
        switch (tableType) {
          case 'DI':
            status = 'Order Served';
            break;
          default:
            status = 'Order Delivered';
            break;
        }
        break;
      case 'FAILED':
        status = 'Order Failed';
        break;
      case 'CANCELLED':
        status = 'Order Cancelled';
        break;
      case 'READY':
        switch (tableType) {
          case 'SP':
            status = 'Order Ready';
            break;
          case 'DL':
            status = 'Out for Delivery';
            break;
          case 'DT':
            status = 'Order Ready';
            break;
          default:
        }
        break;
      default:
    }
  }
  return status;
};

export const renderOrderDescription = ({
  orderStatus,
  cancelledItems,
  unattendedTimer,
  tableType,
  ePrepTime,
  ePickupTime,
  orderPunchTime,
  defaultPreparationTime,
}) => {
  let description = '';
  if (
    (isEqual(orderStatus, orderStatusConfig.UNATTENDED) ||
      isEqual(orderStatus, orderStatusConfig.CONFIRMED) ||
      isEqual(orderStatus, orderStatusConfig.SETTLED)) &&
    cancelledItems &&
    cancelledItems.length > 0
  ) {
    description = constants.confirmation.orderStatusText.cancelledItemsText;
  } else {
    switch (orderStatus) {
      case 'DRAFT':
        description = constants.confirmation.orderStatusText.unAttended;
        break;
      case 'UNATTENDED':
        switch (tableType) {
          case 'DL':
            // @ts-ignore
            description = (
              <>
                <span>{constants.confirmation.orderStatusText.delivery.unAttended}</span>
                <div className="confirmation_description">
                  <img src={mobileIcon} alt="mobile" />{' '}
                  <span>
                    {constants.confirmation.orderStatusText.delivery.unAttendedDescription}
                  </span>
                </div>
              </>
            );
            break;
          case 'SP':
            // @ts-ignore
            description = (
              <>
                <span>{constants.confirmation.orderStatusText.selfPickup.unAttended}</span>
                <div className="confirmation_description">
                  <img src={mobileIcon} alt="mobile" />{' '}
                  <span>
                    {constants.confirmation.orderStatusText.selfPickup.unAttendedDescription}
                  </span>
                </div>
              </>
            );
            break;
          default:
            // @ts-ignore
            description = (
              <span className="confirmation_new-line-separator">
                <FormattedMessage
                  id="unattended"
                  defaultMessage={`Restaurant will shortly confirm your order. \n It may take upto {timer} minutes to confirm. \n Once confirmed it will take around {defaultPreparationTime} mins.`}
                  values={{
                    timer: unattendedTimer,
                    defaultPreparationTime:
                      defaultPreparationTime +
                      constants.confirmation.orderStatusText.addOnDeliveryTime,
                  }}
                />
              </span>
            );
            break;
        }
        break;
      case 'CONFIRMED':
        switch (tableType) {
          case 'DL':
            // @ts-ignore
            description = (
              <>
                <span className="confirmation_new-line-separator">
                  <FormattedMessage
                    id="confirmed"
                    defaultMessage={`Restaurant is preparing your order. \n Your order will be delivered by {ePrepTime}`}
                    values={{
                      ePrepTime: formatTime(
                        orderPunchTime,
                        ePrepTime + constants.confirmation.orderStatusText.addOnDeliveryTime,
                      ),
                    }}
                  />
                </span>
                <div className="confirmation_description">
                  <img src={mobileIcon} alt="mobile" />{' '}
                  <span>
                    {constants.confirmation.orderStatusText.delivery.confirmedDescription}
                  </span>
                </div>
              </>
            );
            break;
          case 'SP':
            // @ts-ignore
            description = (
              <>
                <span className="confirmation_new-line-separator">
                  <FormattedMessage
                    id="confirmed"
                    defaultMessage={`Restaurant is preparing your order. \n Your order will be ready by {ePrepTime}`}
                    values={{
                      ePrepTime: formatTime(orderPunchTime, max([ePrepTime, ePickupTime])),
                    }}
                  />
                </span>
                <div className="confirmation_description">
                  <img src={mobileIcon} alt="mobile" />{' '}
                  <span>
                    {constants.confirmation.orderStatusText.selfPickup.confirmedDescription}
                  </span>
                </div>
              </>
            );
            break;
          default:
            // @ts-ignore
            description = (
              <span className="confirmation_new-line-separator">
                <FormattedMessage
                  id="confirmed"
                  defaultMessage={`Restaurant is preparing your order. \n It will be served by {ePrepTime}`}
                  values={{
                    ePrepTime: formatTime(
                      orderPunchTime,
                      ePrepTime + constants.confirmation.orderStatusText.addOnDeliveryTime,
                    ),
                  }}
                />
              </span>
            );
        }
        break;
      case 'SETTLED':
        switch (tableType) {
          case 'DI':
            description = constants.confirmation.orderStatusText.dineIn.settled;
            break;
          default:
            description = constants.confirmation.orderStatusText.settled;
            break;
        }
        break;
      case 'FAILED':
        description = constants.confirmation.orderStatusText.failed;
        break;
      case 'CANCELLED':
        description = constants.confirmation.orderStatusText.cancelled;
        break;
      case 'READY':
        switch (tableType) {
          case 'DL':
            // @ts-ignore
            description = (
              <>
                <span>{constants.confirmation.orderStatusText.delivery.ready}</span>
                <div className="confirmation_description">
                  <img src={mobileIcon} alt="mobile" />{' '}
                  <span>{constants.confirmation.orderStatusText.delivery.readyDescription}</span>
                </div>
              </>
            );
            break;
          case 'SP':
            // @ts-ignore
            description = (
              <>
                <span>{constants.confirmation.orderStatusText.selfPickup.ready}</span>
                <div className="confirmation_description">
                  <img src={mobileIcon} alt="mobile" />{' '}
                  <span>{constants.confirmation.orderStatusText.selfPickup.readyDescription}</span>
                </div>
              </>
            );
            break;
          default:
            description = constants.confirmation.orderStatusText.selfPickup.ready;
        }
        break;
      default:
    }
  }
  return description;
};

export const getPaymentTypeBtnName = (btnName, additionalProperties) => {
  let name = '';

  switch (btnName) {
    case paymentTypes.POSTPAYMENT:
      const widgetText =
        additionalProperties &&
        get(
          additionalProperties.find((item) => item.code === 'payment.post.title'),
          'value',
          '',
        );
      name = !isEmpty(widgetText) ? widgetText : constants.payment.paymentType.payLater;
      break;
    case paymentTypes.PREPAYMENT:
      name = constants.payment.paymentType.payNow;
      break;
    default:
      break;
  }

  return toTitleCase(name.toLowerCase());
};

/**
 * Gets the error title from the configuration on UI.
 * If not found, fallback to global error title.
 *
 * @param {Object} param Destructurable object
 * @param {Object} param.source Error source component's configuration object
 * @param {string} param.code Error code
 * @returns {string} errorTitle Contextual error title
 */
export const getErrorTitle = ({ source, code }) => {
  switch (code) {
    case 'UNKNOWN_SERVER_ERR':
      return 'Something went wrong';
    case 'ITEM_NOT_AVAILABLE':
      return 'Item(s) not available';
    case 'TOO_MANY_ORDERS':
      return 'Order cannot be placed';
    case 'PAYMENT_ERROR':
      return 'Payment Issue';
    case 'ORDER_TIME_NOT_ALLOWED':
      return 'Not accepting online orders';
    case 'READ_MODE_INVALID_ASSISTANCE':
      return 'Service not available';
    default:
      // TODO: Replace switch block completely with the following code block when the new error handling mechanism is mapped onto contexts
      let errorTitle = '';
      const configuredErrorTitle =
        source && source.errorInfo && source.errorInfo[code] && source.errorInfo[code].title
          ? source.errorInfo[code].title
          : '';
      const configGlobalErrorTitle =
        constants.globalErrorInfo &&
        constants.globalErrorInfo[code] &&
        constants.globalErrorInfo[code].title
          ? constants.globalErrorInfo[code].title
          : constants.globalErrorInfo[customErrorCodes.default].title;
      errorTitle = !isEmpty(configuredErrorTitle) ? configuredErrorTitle : configGlobalErrorTitle;
      return errorTitle;
  }
};

/**
 * Gets the error message from the configuration on UI.
 * If not found, fallback to API provided message and further fallback to global error message.
 *
 * @param {Object} param Destructurable object
 * @param {Object} param.source Error source component's configuration object
 * @param {string} param.code Error code
 * @param {string} [param.message] API provided error message / Custom fallback message
 * @returns {string} errorMessage Contextual error message
 */
export const getErrorDescription = ({ source, code, message }) => {
  switch (code) {
    case 'ITEM_NOT_AVAILABLE':
      return message;
    case 'TOO_MANY_ORDERS':
      return message;
    case 'PAYMENT_ERROR':
      return 'Seems like there is some issue in payment. If payment is deducted, it will be refunded in next 48hrs.';
    case 'READ_MODE_INVALID_ASSISTANCE':
      return message;
    default:
      // TODO: Replace switch block completely with the following code block when the new error handling mechanism is mapped onto contexts
      let errorMessage = '';
      const configErrorMessage =
        source && source.errorInfo && source.errorInfo[code] && source.errorInfo[code].message
          ? source.errorInfo[code].message
          : '';
      const configGlobalErrorMessage =
        constants.globalErrorInfo &&
        constants.globalErrorInfo[code] &&
        constants.globalErrorInfo[code].message
          ? constants.globalErrorInfo[code].message
          : constants.globalErrorInfo[customErrorCodes.default].message;
      errorMessage = !isEmpty(configErrorMessage) ? configErrorMessage : message;
      errorMessage = !isEmpty(errorMessage) ? errorMessage : configGlobalErrorMessage;
      return errorMessage;
  }
};

export const getButtonHandlerCta = ({ source, code }) => {
  switch (code) {
    case 'ITEM_NOT_AVAILABLE':
      return 'Remove Unavailable Item(s)';
    case 'TOO_MANY_ORDERS':
      return 'BACK TO MENU';
    case 'READ_MODE_INVALID_ASSISTANCE':
    case customErrorCodes.contextlessURLHit:
      return constants.globalErrorInfo[customErrorCodes.contextlessURLHit].CTAText;
    default:
      // TODO: Replace switch block completely with the following code block when the new error handling mechanism is mapped onto contexts
      let errorCTA = '';
      const configuredErrorCTA =
        source && source.errorInfo && source.errorInfo[code] && source.errorInfo[code].CTAText
          ? source.errorInfo[code].CTAText
          : '';
      const configGlobalErrorCTA =
        constants.globalErrorInfo &&
        constants.globalErrorInfo[code] &&
        constants.globalErrorInfo[code].CTAText
          ? constants.globalErrorInfo[code].CTAText
          : constants.globalErrorInfo[customErrorCodes.default].CTAText;
      errorCTA = !isEmpty(configuredErrorCTA) ? configuredErrorCTA : configGlobalErrorCTA;
      return errorCTA;
  }
};

export const getRestaurantOperatingMode = (restaurantMode, serviceTypeStatus) => {
  let mode = '';
  if (
    isEqual(restaurantMode, restaurantOperatingMode.READ) ||
    isEqual(restaurantMode, restaurantOperatingMode.DEACTIVE)
  ) {
    mode = restaurantMode;
  } else {
    switch (serviceTypeStatus) {
      case restaurantOperatingMode.CLOSED:
        mode = restaurantOperatingMode.CLOSED;
        break;
      case restaurantOperatingMode.NOTOPEN:
        mode = restaurantOperatingMode.NOTOPEN;
        break;
      case restaurantOperatingMode.STOPPED:
        mode = restaurantOperatingMode.STOPPED;
        break;
      default:
        break;
    }
  }
  return mode;
};

export const renderRestaurantOperatingModeMessage = (mode, type) => {
  switch (mode) {
    case restaurantOperatingMode.READ:
    case restaurantOperatingMode.DEACTIVE:
      return constants.restaurantOperatingModeMessages.READ;
    case restaurantOperatingMode.CLOSED:
      return constants.restaurantOperatingModeMessages.CLOSED;
    case restaurantOperatingMode.NOTOPEN:
      return constants.restaurantOperatingModeMessages.NOTOPEN;
    case restaurantOperatingMode.STOPPED:
      return (
        <FormattedMessage
          id="stopped"
          defaultMessage={`${constants.restaurantOperatingModeMessages.notAcceptingText} {serviceType} ${constants.restaurantOperatingModeMessages.ordersText}`}
          values={{
            serviceType: renderServiceType({ serviceMode: type }),
          }}
        />
      );
    default:
      return;
  }
};

export const getServiceTypeCode = ({ selectedTab }) => {
  let serviceType = {
    delivery: 'DL',
    'self-pickup': 'SP',
  };
  return serviceType[selectedTab];
};

export const getServiceTypeLabel = ({ serviceType }) => {
  let serviceTypeLabel = {
    delivery: constants.scanCode.deliveryOrders,
    'self-pickup': constants.scanCode.selfPickUpOrders,
  };
  return serviceTypeLabel[serviceType] || constants.scanCode.ordersText;
};

export const renderBtnName = (pageSrc) => {
  switch (pageSrc) {
    case routes.offers.availOffer:
      return constants.menu.select;
    default:
      return constants.menu.add;
  }
};

export const renderGetAppetizerButton = (size) => {
  switch (size) {
    case buttonSize.short:
      return <img src={appetizerShort} alt="install" />;
    case buttonSize.long:
      return <img src={appetizerLong} alt="install" />;
    default:
      return <img src={appetizerShort} alt="install" />;
  }
};

export const getMinCartValueError = (src) => {
  switch (src) {
    case routes.containers.restaurant:
      return {
        title: upsellConfig.cartNotEnoughForDealPopup.title,
        description: upsellConfig.cartNotEnoughForDealPopup.description,
        popupCTA: upsellConfig.cartNotEnoughForDealPopup.popupCTA,
        secondaryCTA: upsellConfig.cartNotEnoughForDealPopup.secondaryCTA,
      };
    case routes.containers.cart:
      return {
        title: upsellConfig.removeItemAndLooseDealPopup.title,
        description: upsellConfig.removeItemAndLooseDealPopup.description,
        popupCTA: upsellConfig.removeItemAndLooseDealPopup.popupCTA,
        secondaryCTA: upsellConfig.removeItemAndLooseDealPopup.secondaryCTA,
      };
    default:
      return {
        title: upsellConfig.addMoreItemsPopup.title,
        description: upsellConfig.addMoreItemsPopup.description,
        popupCTA: upsellConfig.addMoreItemsPopup.popupCTA,
      };
  }
};

export const getUpsellErrorPopupContent = (errorCode, src) => {
  let upsellPopupContent = {
    [upsellSearchErrorCase.dealAlreadyTakenError]: {
      title: upsellConfig.dealAlreadyTakenPopup.title,
      description: upsellConfig.dealAlreadyTakenPopup.description,
      popupCTA: upsellConfig.dealAlreadyTakenPopup.popupCTA,
    },
    [upsellSearchErrorCase.minCartValueError]: getMinCartValueError(src),
    [upsellSearchErrorCase.noDealLeftError]: {
      title: upsellConfig.noDealsLeftPopup.title,
      description: upsellConfig.noDealsLeftPopup.description,
      popupCTA: upsellConfig.noDealsLeftPopup.popupCTA,
    },
    [upsellSearchErrorCase.dealUnavailableError]: {
      title: upsellConfig.dealUnavailablePopup.title,
      description: upsellConfig.dealUnavailablePopup.description,
      popupCTA: upsellConfig.dealUnavailablePopup.popupCTA,
    },
  };
  return upsellPopupContent[errorCode];
};

export const getPaymentModeLabel = (paymentType) => {
  switch (paymentType) {
    case paymentTypes.loyaltyPaymentTypes.CASHCARD:
      return constants.confirmation.paymentDetails.paymentMode.cashCard;
    case paymentTypes.PREPAYMENT:
      return constants.confirmation.paymentDetails.paymentMode.prepaid;
    case paymentTypes.POSTPAYMENT:
      return constants.confirmation.paymentDetails.paymentMode.postpaid;
    default:
      return '';
  }
};

export const getPaymentStatus = ({
  orderStatus,
  paymentType,
  paymentStatus,
  transactionSubStatus,
}) => {
  switch (paymentType) {
    case paymentTypes.POSTPAYMENT:
      return (
        (orderStatus === orderStatusConfig.SETTLED &&
          constants.confirmation.paymentDetails.paymentStatus.successful) ||
        constants.confirmation.paymentDetails.paymentStatus.pending
      );

    case paymentTypes.PREPAYMENT:
    case paymentTypes.loyaltyPaymentTypes.CASHCARD:
      let txnSubStatus = '';
      switch (transactionSubStatus) {
        case transactionSubStatusConfig.userDropped:
          txnSubStatus = constants.confirmation.paymentDetails.paymentStatus.userDropped;
          break;
        case transactionSubStatusConfig.invalid:
          txnSubStatus = txnSubStatus =
            constants.confirmation.paymentDetails.paymentStatus.invalidTxn;
          break;
        default:
          txnSubStatus = transactionSubStatus;
          break;
      }
      if (txnSubStatus) {
        return `${paymentStatus} (${txnSubStatus})`;
      }
      return paymentStatus || '';
    default:
      return '';
  }
};

export const getRefundModeLabel = (refundMode) => {
  switch (refundMode) {
    case refundTypesConfig.loyaltyRefundTypes.CASHCARD:
      return constants.confirmation.refundDetails.refundMode.cashCard;
    case refundTypesConfig.prepaid:
      return constants.confirmation.refundDetails.refundMode.prepaid;
    default:
      return '';
  }
};

export const getRefundStatus = (status) => {
  switch (status) {
    case refundStatusConfig.success:
      return constants.confirmation.refundDetails.refundStatus.processed;
    case refundStatusConfig.initial:
    case refundStatusConfig.pending:
      return constants.confirmation.refundDetails.refundStatus.initiated;
    case refundStatusConfig.failed:
      return constants.confirmation.refundDetails.refundStatus.failed;
    default:
      return;
  }
};

export const getRefundStatusUpdateDate = (refund, refundMode) => {
  const isCashCardRefund = refundMode === refundTypesConfig.loyaltyRefundTypes.CASHCARD;
  const refundStatus = get(refund, 'status', '');
  const initiateDate = isCashCardRefund
    ? get(refund, 'initiateDate', '')
    : get(refund, 'transactionDate', '');
  const completeDate = isCashCardRefund
    ? get(refund, 'completeDate', '')
    : get(refund, 'processedDate', '');

  switch (refundStatus) {
    case refundStatusConfig.success:
      return { initiateDate, completeDate };
    case refundStatusConfig.initial:
    case refundStatusConfig.pending:
    case refundStatusConfig.failed:
    default:
      return { initiateDate };
  }
};

export const getTrueCallerDeepLink = ({ requestId }) => {
  const trueCallerDeeplink = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${requestId}&partnerKey=${trueCallerConfig.appKey}&partnerName=${trueCallerConfig.appName}&lang=${trueCallerConfig.lang}&loginPrefix=${trueCallerConfig.loginPrefix}&loginSuffix=${trueCallerConfig.loginSuffix}&ctaPrefix=${trueCallerConfig.ctaPrefix}&ctaColor=${trueCallerConfig.ctaColor}&ctaTextColor=${trueCallerConfig.ctaTextColor}&btnShape=${trueCallerConfig.btnShape}&skipOption=${trueCallerConfig.skipOption}`;
  return trueCallerDeeplink;
};

export const getTrueCallerErrorContent = ({ code, message }) => {
  let errorContent = {
    title: getErrorTitle({ source: authenticationConfig, code }),
    description: getErrorDescription({
      source: authenticationConfig,
      code,
      message,
    }),
    buttonCta: authenticationConfig.tryAgainCTA,
  };
  return errorContent;
};

export const getEmailPopupProps = (emailOpType) => {
  const emailFieldConfig = constants.updateProfileFields.email;

  return {
    popupTitle: emailFieldConfig[emailOpType].title,
    popupDescription: emailFieldConfig[emailOpType].description,
    popupCTAText: emailFieldConfig[emailOpType].btnCTA,
  };
};

export const getTransactionStatusColorClass = (category = '', status = '') => {
  switch (status) {
    case transactionStatusConfig.success:
      return transactionCategory[category] ? transactionCategory[category].styleClass : '';
    case transactionStatusConfig.pending:
    case transactionStatusConfig.initial:
      return 'yellow';
    case transactionStatusConfig.failed:
      return 'black';
  }
};

export const getRenderableCashCardTransactionStatus = (type, status) => {
  const cashCardTransactionsResources = constants.cashCard.guestCashCardTransactions;

  return (
    (type === cashCardTransactionTypes.refund &&
      [transactionStatusConfig.initial, transactionStatusConfig.pending].includes(status) &&
      cashCardTransactionsResources.refundPendingLabel) ||
    (type === cashCardTransactionTypes.refund &&
      status === transactionStatusConfig.failed &&
      cashCardTransactionsResources.refundFailedLabel) ||
    (type === cashCardTransactionTypes.refund &&
      status === transactionStatusConfig.success &&
      cashCardTransactionsResources.refundSuccessLabel) ||
    ([transactionStatusConfig.initial, transactionStatusConfig.pending].includes(status) &&
      cashCardTransactionsResources.paymentPendingLabel) ||
    (status === transactionStatusConfig.failed &&
      cashCardTransactionsResources.paymentFailedLabel) ||
    ''
  );
};

export const getCashCardRechargeStatusImageSource = (status) => {
  switch (status) {
    case transactionStatusConfig.success:
      return rechargeSuccessIcon;
    case transactionStatusConfig.initial:
    case transactionStatusConfig.pending:
      return warningYellowIcon;
    case transactionStatusConfig.failed:
      return warningBlackIcon;
    default:
      return '';
  }
};

export const getRenderableCashCardTransactionStatusSupportLabel = (type, status) => {
  const rechargeConfirmationScreenResources =
    constants.cashCard.rechargeScreen.rechargeConfirmation;

  return (
    (type === cashCardTransactionTypes.refund &&
      [transactionStatusConfig.initial, transactionStatusConfig.pending].includes(status) &&
      rechargeConfirmationScreenResources.refundPendingLabel) ||
    (type === cashCardTransactionTypes.refund &&
      status === transactionStatusConfig.failed &&
      rechargeConfirmationScreenResources.refundFailedLabel) ||
    ([transactionStatusConfig.initial, transactionStatusConfig.pending].includes(status) &&
      rechargeConfirmationScreenResources.rechargePendingLabel) ||
    (status === transactionStatusConfig.failed &&
      rechargeConfirmationScreenResources.rechargeFailedLabel) ||
    ''
  );
};

export const getRenderableCashCashTransactionStatusInstructionLabel = (
  type,
  status,
  restaurantEmail,
) => {
  const rechargeConfirmationScreenResources =
    constants.cashCard.rechargeScreen.rechargeConfirmation;

  return (
    ([transactionStatusConfig.initial, transactionStatusConfig.pending].includes(status) &&
      rechargeConfirmationScreenResources.transactionPendingInstruction) ||
    (type === cashCardTransactionTypes.refund &&
      status === transactionStatusConfig.failed &&
      `${rechargeConfirmationScreenResources.refundFailedInstruction} ${restaurantEmail}`) ||
    (status === transactionStatusConfig.failed &&
      rechargeConfirmationScreenResources.rechargeFailedInstruction)
  );
};

export const getLocalityAddressFromAddressComponent = (addressComponent) => {
  let subLocality = '';
  let locality = '';
  let administrativeArea = '';
  if (!isEmpty(addressComponent)) {
    //Set subLocality from addressComponent
    subLocality = get(
      addressComponent.find(
        (addressObj) => addressObj.types && addressObj.types.includes(addressType.route),
      ) || {},
      'long_name',
      get(
        addressComponent.find(
          (addressObj) =>
            addressObj.types && addressObj.types.includes(addressType.subLocalityLevelSecond),
        ) || {},
        'long_name',
        get(
          addressComponent.find(
            (addressObj) =>
              addressObj.types && addressObj.types.includes(addressType.subLocalityLevelFirst),
          ) || {},
          'long_name',
          get(
            addressComponent.find(
              (addressObj) => addressObj.types && addressObj.types.includes(addressType.plus_code),
            ) || {},
            'long_name',
            '',
          ),
        ),
      ),
    );
    if (
      isEqual(
        subLocality,
        get(
          addressComponent.find(
            (addressObj) =>
              addressObj.types && addressObj.types.includes(addressType.subLocalityLevelSecond),
          ) || {},
          'long_name',
        ),
      )
    ) {
      var localityLevelFirst = get(
        addressComponent.find(
          (addressObj) =>
            addressObj.types && addressObj.types.includes(addressType.subLocalityLevelFirst),
        ) || {},
        'long_name',
      );
      var localityLevelThird = get(
        addressComponent.find(
          (addressObj) =>
            addressObj.types && addressObj.types.includes(addressType.subLocalityLevelThird),
        ) || {},
        'long_name',
      );
      if (!isEmpty(localityLevelFirst)) {
        subLocality = subLocality + ', ' + localityLevelFirst;
      }
      if (!isEmpty(localityLevelThird)) {
        subLocality = localityLevelThird + ', ' + subLocality;
      }
    }

    //Set locality from addressComponent
    locality = get(
      addressComponent.find(
        (addressObj) => addressObj.types && addressObj.types.includes(addressType.locality),
      ) || {},
      'long_name',
      '',
    );

    //Set administrativeArea from addressComponent
    administrativeArea = get(
      addressComponent.find(
        (addressObj) =>
          addressObj.types && addressObj.types.includes(addressType.administrativeAreaLevelFirst),
      ) || {},
      'long_name',
      '',
    );
  }

  return { subLocality, locality, administrativeArea };
};

export const prepareCouponDiscountLine = ({
  discountValue,
  discountType,
  discountUnit,
  minOrderValue,
  applicableItems,
  maxDiscountValue,
  discountMinValue,
}) => {
  let couponDiscountLine = '';
  const applicableItem =
    applicableItems &&
    applicableItems.length > 0 &&
    get(applicableItems.find((item) => item) || {}, 'itemName', '');
  switch (discountType) {
    case offerTypeCode.DirectDiscount:
      if (!!discountValue) {
        switch (discountUnit) {
          case 'PER':
            couponDiscountLine = `${discountValue}% Off${
              minOrderValue ? ` on minimum bill of ${currencies.INR.symbol}${minOrderValue}` : ''
            }${maxDiscountValue ? ` upto ${currencies.INR.symbol}${maxDiscountValue}` : ''}`;
            break;
          case 'ABS':
            couponDiscountLine = `${currencies.INR.symbol}${discountValue} Off${
              minOrderValue ? ` on minimum bill of ${currencies.INR.symbol}${minOrderValue}` : ''
            }`;
            break;
          default:
            break;
        }
      }
      break;
    case offerTypeCode.ItemFree:
      couponDiscountLine = `Free ${applicableItem} ${
        minOrderValue ? ` on minimum bill of ${currencies.INR.symbol}${minOrderValue}` : ''
      }`;
      break;
    case offerTypeCode.ItemOffer:
      couponDiscountLine = `Buy ${discountMinValue} Get ${discountValue} Free ${applicableItem} ${
        minOrderValue ? ` on minimum bill of ${currencies.INR.symbol}${minOrderValue}` : ''
      }`;
      break;
    case offerTypeCode.ComboOffer:
      couponDiscountLine = `${applicableItem} @ ${currencies.INR.symbol}${discountValue} ${
        minOrderValue ? ` on minimum bill of ${currencies.INR.symbol}${minOrderValue}` : ''
      }`;
      break;
    default:
      break;
  }
  return couponDiscountLine;
};

export const renderStreakCouponTitle = ({
  discountValue,
  discountType,
  discountUnit,
  dealItems,
  discountMinValue,
}) => {
  let couponTitle = '';
  const itemName = get(dealItems, '0.itemName', '');
  switch (discountType) {
    case offerTypeCode.DirectDiscount:
      if (!!discountValue) {
        switch (discountUnit) {
          case 'PER':
            couponTitle = `${discountValue}% Off`;
            break;
          case 'ABS':
            couponTitle = `${currencies.INR.symbol}${discountValue} Off`;
            break;
          default:
            break;
        }
      }
      break;
    case offerTypeCode.ItemFree:
      if (dealItems && dealItems.length > 1) {
        couponTitle = `Free ${itemName} & more`;
      } else {
        couponTitle = `Free ${itemName}`;
      }
      break;
    case offerTypeCode.ItemOffer:
      if (dealItems && dealItems.length > 1) {
        couponTitle = `Buy ${discountMinValue} Get ${discountValue} Free ${itemName} & more`;
      } else {
        couponTitle = `Buy ${discountMinValue} Get ${discountValue} Free ${itemName}`;
      }
      break;
    case offerTypeCode.ComboOffer:
      if (dealItems && dealItems.length > 1) {
        couponTitle = `${itemName} & more @ ${currencies.INR.symbol}${discountValue}`;
      } else {
        couponTitle = `${itemName} @ ${currencies.INR.symbol}${discountValue}`;
      }
      break;
    default:
      break;
  }
  return couponTitle;
};

export const prepareCouponDateRange = ({ startDate, endDate }) => {
  let result = '';
  // const presentableEndTime = moment(endTime).subtract(1, 'days'); // Effective end date, as users would assume that the campaign runs for the whole day of the last date
  let showStartDate = true;
  let showEndDate = true;
  const couponStartDate = startDate && formatDate(startDate);
  // const couponEndTime = presentableEndTime && formatDate(presentableEndTime);
  const couponEndDate = endDate && formatDate(endDate);

  const showSingleDate = startDate && endDate && moment(startDate).isSame(moment(endDate), 'date');
  if (
    differenceBetween(moment(), couponStartDate, 'days', 'day') >= 0 &&
    (differenceBetween(couponEndDate, moment(), 'days', 'day') > 0 || isEmpty(couponEndDate))
  ) {
    // start date is past and end date is future or null
    showStartDate = false;
  }

  if (couponStartDate && couponEndDate && showStartDate && showEndDate) {
    result = showSingleDate ? (
      <FormattedMessage
        id="validOn"
        defaultMessage={resourcesConfig.validOn}
        values={{ time: couponStartDate }}
      />
    ) : (
      <FormattedMessage
        id="validFromTo"
        defaultMessage={resourcesConfig.validFromTo}
        values={{ redeemableTime: couponStartDate, expireTime: couponEndDate }}
      />
    );
  } else if (couponStartDate && showStartDate) {
    result = (
      <FormattedMessage
        id="validFrom"
        defaultMessage={resourcesConfig.validFrom}
        values={{ redeemableTime: couponStartDate }}
      />
    );
  } else if (couponEndDate && showEndDate) {
    result = (
      <FormattedMessage
        id="validTill"
        defaultMessage={resourcesConfig.validTill}
        values={{ expireTime: couponEndDate }}
      />
    );
  }
  return result;
};

// Compare function takes values being compared as parameters from sort() function and returns a comparator (integer) - negative implying first comes before, zero implying same and positive implying after, post sorting.
const compareWeekdayFunction = (firstValue, secondValue, weekdays) => {
  const comparator =
    get(
      find(weekdays, (day) => get(day, 'code', '') === firstValue),
      'ordinal',
      1,
    ) -
    get(
      find(weekdays, (day) => get(day, 'code', '') === secondValue),
      'ordinal',
      1,
    );
  return comparator;
};

export const prepareCouponWeekDays = (couponWeekDays, weekdays, typeOf) => {
  if (isEqual(typeOf, 'arrOfString') || typeof get(couponWeekDays, '0') === 'string') {
    let weekdaysArr = couponWeekDays.map((weekday) => capitalize(weekday));
    return weekdaysArr && weekdaysArr.join(', ');
  } else {
    let weekdaysArr = groupBy(couponWeekDays, 'day'); // Restructure weekdays grouping them based on 'day' key
    weekdaysArr = sortObjectByKeysUsingComparator(weekdaysArr, (firstValue, secondValue) =>
      compareWeekdayFunction(firstValue, secondValue, weekdays),
    );
    weekdaysArr = Object.values(weekdaysArr);
    return (
      weekdaysArr &&
      weekdaysArr.map((weekday, index) =>
        capitalize(`${get(weekday, '0.day', '')}${weekdaysArr.length > index + 1 ? ', ' : ''}`),
      )
    );
  }
};

export const prepareCouponDailyTimings = ({ dailyStartTime, dailyEndTime }) => {
  let result = '';
  const couponDailyStartTime = dailyStartTime && formatTimeTo12H(dailyStartTime);
  const couponDailyEndTime = dailyEndTime && formatTimeTo12H(dailyEndTime);
  if (couponDailyStartTime && couponDailyEndTime) {
    result = `Applicable from ${couponDailyStartTime} to ${couponDailyEndTime}`;
  } else if (couponDailyStartTime) {
    result = `Applicable from ${couponDailyStartTime}`;
  } else if (couponDailyEndTime) {
    result = `Applicable before ${couponDailyEndTime}`;
  }
  return result;
};

export const prepareCouponStreaxDateRange = ({
  startDate,
  endDate,
  activeDays,
  dealSequence,
  activeDayCounter,
  streakEndDate,
}) => {
  let result = '';
  const couponStartDate = startDate && formatDate(startDate);
  let couponEndDate = endDate && formatDate(endDate);
  couponEndDate = !isEmpty(couponEndDate)
    ? formatDate(couponEndDate)
    : streakEndDate && formatDate(streakEndDate);
  if (dealSequence === 1) {
    if (moment(couponStartDate).isAfter()) {
      result = `Valid from ${couponStartDate} to ${couponEndDate}`;
    } else if (!isEmpty(couponEndDate)) {
      result = `Valid till ${couponEndDate}`;
    }
  } else {
    result = activeDays
      ? `Valid for ${activeDays} days after the ${
          isEqual(activeDayCounter, 'PREV') ? `${renderCount(dealSequence - 1)}` : 'first'
        } order${!isEmpty(couponEndDate) ? ` or ${couponEndDate}, whichever is earlier.` : ''}`
      : !isEmpty(couponEndDate)
      ? `Valid till ${couponEndDate}`
      : '';
  }
  return !isEmpty(result) && <li>{result}</li>;
};

export const getMemberIdFromLocalStorage = (guestId, restaurantCode) => {
  const memberIds = getItem(storageTypes.localStorage, 'memberIds') || [];

  if (isEmpty(memberIds)) return null;

  const matchingMemberIdObject = memberIds.find(
    (memberId) =>
      get(memberId, 'guestId') === guestId && get(memberId, 'restaurantCode') === restaurantCode,
  );

  return matchingMemberIdObject;
};

export const setMemberIdIntoLocalStorage = (guestId, restaurantCode, inputMemberId) => {
  const memberIds = getItem(storageTypes.localStorage, 'memberIds') || [];

  const matchingMemberIdIndex = memberIds.findIndex(
    (memberId) =>
      get(memberId, 'guestId') === guestId && get(memberId, 'restaurantCode') === restaurantCode,
  );

  // If match not found
  if (matchingMemberIdIndex < 0) {
    memberIds.push({ guestId, restaurantCode, memberId: inputMemberId });
  } else {
    memberIds[matchingMemberIdIndex] = {
      ...memberIds[matchingMemberIdIndex],
      memberId: inputMemberId,
    };
  }

  localStorage.setItem('memberIds', JSON.stringify(memberIds));
};

export const isMembershipModuleEnabledForRestaurant = (restaurantConfigAdditionalProps) => {
  const additionalProps = restaurantConfigAdditionalProps || [];
  const membershipProp =
    additionalProps.find((prop) => get(prop, 'code') === 'membership.isEnabled') || {};

  return !isEmpty(membershipProp) && membershipProp.value;
};

export const isMembershipIdMandatoryForRestaurant = (restaurantConfigAdditionalProps) => {
  const additionalProps = restaurantConfigAdditionalProps || [];
  const membershipProp =
    additionalProps.find((prop) => get(prop, 'code') === 'membership.isMandatory') || {};

  return !isEmpty(membershipProp) && membershipProp.value && JSON.parse(membershipProp.value);
};

export const isEngageDealOffer = (offer) => {
  return get(offer, 'category', '') === 'DC';
};

export const isNonApplicableEngageDealOffer = (offer) => {
  const offerType = get(offer, 'type', '');

  return (
    isEngageDealOffer(offer) &&
    [offerTypeCode.ItemOffer, offerTypeCode.ComboOffer].includes(offerType)
  );
};

export const shouldOfferBeVisibleOnMenu = (offer) => {
  // if engage deal offer is of type BOGO or Combo, skip isActive check
  if (isNonApplicableEngageDealOffer(offer)) {
    return true;
  }

  return get(offer, 'isActive', false) === true;
};

export const renderFeedbackSection = (
  order,
  handleSubmitFeedbackButtonCTA,
  isOrderDetailsPage = false,
  handleFeedbackDetailsCTA,
  resourcesFeedbackQuestions = {},
) => {
  const overallRating = get(order, 'feedbackList[0].overallRating', 5);
  const feedbackRemark = get(order, 'feedbackList[0].remark', '');
  const feedbackObj = get(order, 'feedbackList[0]', {});
  const getQuestionByType = (type) => {
    const questionObj = resourcesFeedbackQuestions.find((question) => question.type === type);
    return questionObj ? get(questionObj, 'question', '') : '';
  };
  const feedbackQuestionArray = [
    {
      name: 'overallRating',
      value: !isEqual(get(feedbackObj, 'overallRating', 0), 0),
      text: !isEmpty(resourcesFeedbackQuestions)
        ? !isEmpty(getQuestionByType('overall'))
          ? getQuestionByType('overall')
          : resourcesConfig.overallRatingQuestion
        : resourcesConfig.overallRatingQuestion,
      rating: get(feedbackObj, 'overallRating', 0),
    },
    {
      name: 'foodRating',
      value: !isEqual(get(feedbackObj, 'foodRating', 0), 0),
      text: !isEmpty(resourcesFeedbackQuestions)
        ? !isEmpty(getQuestionByType('food'))
          ? getQuestionByType('food')
          : resourcesConfig.foodRatingQuestion
        : resourcesConfig.foodRatingQuestion,
      rating: get(feedbackObj, 'foodRating', 0),
    },
    {
      name: 'serviceRating',
      value: !isEqual(get(feedbackObj, 'serviceRating', 0), 0),
      text: !isEmpty(resourcesFeedbackQuestions)
        ? !isEmpty(getQuestionByType('service'))
          ? getQuestionByType('service')
          : resourcesConfig.serviceRatingQuestion
        : resourcesConfig.serviceRatingQuestion,
      rating: get(feedbackObj, 'serviceRating', 0),
    },
    {
      name: 'ambienceRating',
      value: !isEqual(get(feedbackObj, 'ambienceRating', 0), 0),
      text: !isEmpty(resourcesFeedbackQuestions)
        ? !isEmpty(getQuestionByType('ambience'))
          ? getQuestionByType('ambience')
          : resourcesConfig.ambienceRatingQuestion
        : resourcesConfig.ambienceRatingQuestion,
      rating: get(feedbackObj, 'ambienceRating', 0),
    },
  ];
  const filteredFeedbackQuestions = feedbackQuestionArray.filter((question) =>
    get(question, 'value', false),
  );
  return (
    <div className="feedback-section-CTA">
      {get(order, 'feedbackList') && !isEmpty(get(order, 'feedbackList')) ? (
        <div className="ratings-section">
          {isOrderDetailsPage && <div className="feedback-line-break"></div>}
          <span>
            <FormattedMessage id="yourRatingText" defaultMessage={resourcesConfig.yourRatingText} />
          </span>
          <div className="rating-icons-section">
            {Array.from({ length: overallRating }).map((icon, index) => (
              <img key={index} src={starFillIcon} alt="Star Icon" className="star-icon-style" />
            ))}
          </div>
          {isOrderDetailsPage && (
            <>
              {!isEmpty(feedbackRemark) && (
                <div className="feedback-remark-section">{feedbackRemark}</div>
              )}
              {filteredFeedbackQuestions.length > 1 && (
                <div
                  className="feedback-detailsCTA-section"
                  onClick={() => handleFeedbackDetailsCTA(filteredFeedbackQuestions)}
                >
                  <FormattedMessage
                    id="viewAllRatingsCTA"
                    defaultMessage={resourcesConfig.viewAllRatingsCTA}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <Button
          classList="submit-feedback-button"
          content={
            <FormattedMessage
              id="rateYourExperienceCTA"
              defaultMessage={resourcesConfig.rateYourExperienceCTA}
            />
          }
          onClick={(e) => {
            e.stopPropagation();
            handleSubmitFeedbackButtonCTA(order);
          }}
        />
      )}
    </div>
  );
};
