import {
  API_ERROR_RESPONSE,
  BEGIN_AJAX_CALL,
  DISPATCH_CONTEXTUAL_HOME,
  END_AJAX_CALL,
  FETCH_BRAND_RESOURCES,
  FETCH_MASTER_DATA,
  FETCH_OUTLET_DETAILS,
  FETCH_APP_STARTUP_INFO,
  DISPATCH_BRAND_RESOURCES,
  FETCH_BRAND_CITIES,
  DISPATCH_APPLICABLE_BRAND_MODULES,
  DISPATCH_GUEST_LOCATION,
  DISPATCH_APP_RESOLUTION_CONTEXT,
  CLEAR_APP_RESOLUTION_CONTEXT,
  DISPATCH_GUEST_COUPON_AND_DEAL_DATA,
  DISPATCH_STATIC_CONTEXT,
} from '../../actions/common/actionType';

const common = (state = {}, action = {}) => {
  if (action.type === BEGIN_AJAX_CALL) {
    const { showSpinner } = action;
    return {
      ...state,
      showSpinner,
    };
  } else if (action.type === END_AJAX_CALL) {
    const { showSpinner } = action;
    return {
      ...state,
      showSpinner,
    };
  } else if (action.type === API_ERROR_RESPONSE) {
    const { errorResponse } = action;
    return {
      ...state,
      errorResponse,
    };
  } else if (action.type === FETCH_MASTER_DATA) {
    const { masterData } = action;
    return {
      ...state,
      masterData,
    };
  } else if (action.type === FETCH_BRAND_RESOURCES) {
    const { resources } = action;
    return {
      ...state,
      resources,
    };
  } else if (action.type === FETCH_OUTLET_DETAILS) {
    const { outletDetails } = action;
    return {
      ...state,
      outletDetails,
    };
  } else if (action.type === DISPATCH_CONTEXTUAL_HOME) {
    const { contextualHome } = action;
    return {
      ...state,
      contextualHome,
    };
  } else if (action.type === FETCH_APP_STARTUP_INFO) {
    const { appStartupInfo } = action;
    return {
      ...state,
      appStartupInfo,
    };
  } else if (action.type === DISPATCH_BRAND_RESOURCES) {
    const { brandResources } = action;
    return {
      ...state,
      resources: brandResources,
    };
  } else if (action.type === FETCH_BRAND_CITIES) {
    const { brandCities } = action;
    return {
      ...state,
      brandCities,
    };
  } else if (action.type === DISPATCH_APPLICABLE_BRAND_MODULES) {
    const { modules } = action;
    return {
      ...state,
      modules,
    };
  } else if (action.type === DISPATCH_GUEST_LOCATION) {
    const { guestLocation } = action;
    return {
      ...state,
      guestLocation,
    };
  } else if (action.type === DISPATCH_APP_RESOLUTION_CONTEXT) {
    const { appResolutionContext } = action;
    return {
      ...state,
      appResolutionContext,
    };
  } else if (action.type === DISPATCH_GUEST_COUPON_AND_DEAL_DATA) {
    const { guestCouponAndDealData } = action;
    return {
      ...state,
      guestCouponAndDealData,
    };
  } else if (action.type === DISPATCH_STATIC_CONTEXT) {
    const { staticContext } = action;
    return {
      ...state,
      staticContext,
    };
  }
  return state;
};

export default common;
